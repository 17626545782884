import {AppElement, html} from '../AppElement.js';

export default class PageGroupInsights extends AppElement {
    connectedCallback() {
        super.connectedCallback()
        window.addEventListener("message", infinito.vao.messaging.handler)
    }

    disconnectedCallback() {
        super.disconnectedCallback()

        window.removeEventListener("message", infinito.vao.messaging.handler)
    }
    updated(...args) {
        super.updated(...args);
        window.addEventListener("message", infinito.vao.messaging.handler)
    }
    getButtonStyle(metric) {
        return this.performanceMetric === metric
            ? 'background-color: white; color: black; font-weight: medium; padding: 6px 12px; margin: 0 8px; border-radius: 8px; border: none;'
            : 'background-color: #f2f4f5; color: grey; font-weight: normal; padding: 6px 12px; margin: 0 8px; border-radius: 8px; border: none;';
    }

    render() {
        const authToken = localStorage.getItem("token")
        const recordDate = window.infinito.vao.controller.storageHelper.getCurrentDate();
        const hotelGroup = window.infinito.vao.controller.storageHelper.getSelectedGroup();
        return html`
            <div style="height: 100%">
                <iframe src="https://web.infinito.solutions/app/dashboard/summary?embed=true&authToken=${authToken}&groupId=${hotelGroup.id}&recordDate=${recordDate}"
                        frameBorder="0" style="width: 100%; height: 100%;"></iframe>
            </div>
        `
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageGroupInsights = PageGroupInsights;
customElements.define('vao-page-group-insights', PageGroupInsights);
